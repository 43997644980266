import http from './http'

// 登录
export const logions=(params)=>http.post('/webLogin/public/login',params)
// 在线留言
export const leaveMessage=(params)=>http.post('/webPublic/public/leaveMessage',params)

// 首页
// 轮播图
export const postBanner=()=>http.post('/webindex/public/bannerList')
// 商品列表
export const postProduct=(params)=>http.post('/webindex/public/productList',params)
// 商品详情
export const postDetils=(params)=>http.post(`/webindex/public/productDetils`,params)
// 商品评价
export const postEvaluate=(params)=>http.post('/webindex/public/productEvaluateList',params)
// 获取所有分类
export const allType=()=>http.post('/webindex/public/getAllType')

// 1:关于我们2:用户下单协议
export const postSys=(params)=>http.post('/webPublic/public/getSys',params)

// 我的
// 意见反馈
export const feedback=(params)=>http.post('/webMy/private/addFeedback',params)
// 我的反馈
export const backlist=(params)=>http.post('/webMy/private/myFeedbackList',params)
// 我的评价
export const evaluate=(params)=>http.post('/webMy/private/myEvaluateList',params)
// 我的订单
export const myOrder=(params)=>http.post('/webMy/private/myOrderList',params)
// 去评价
export const evaluateOrder=(params)=>http.post('/webMy/private/addEvaluate',params)
// 删除评价
export const deleteEvaluate=(params)=>http.post('/webMy/private/deleteEvaluate',params)

// 购物车
// 添加购物车
export const addshopCar=(params)=>http.post('/webCar/private/addCar',params)
// 获取购物车
export const carlist=()=>http.post('/webCar/private/getCarDetils')
// 修改购物车数量
export const shopcarNum=(params)=>http.post('/webCar/private/updateCarAmount',params)
// 删除购物车数量
export const detelecar=(params)=>http.post('/webCar/private/deteleCar',params)

// 订单
// 直接购买
export const postOrder=(params)=>http.post('/webOrder/private/createOrder',params)
// 购物车购买
export const postOrdercar=(params)=>http.post('/webOrder/private/createOrderByCarIds',params)

// 收货地址
// 获取默认收货地址
export const defAddress=()=>http.post('/address/private/getDefAddress')
// 获取收货地址列表
export const postAddordes=()=>http.post('/address/private/getAddressList')
// 添加收货地址
export const addAddress=(params)=>http.post('/address/private/addAddress',params)
// 修改默认收货地址
export const updefAddress=(params)=>http.post('/address/private/updateAddressDef',params)
// 删除收货地址
export const deleteAddress=(params)=>http.post('/address/private/deleteAddress',params)
// 修改收货地址
export const upAddress=(params)=>http.post('/address/private/updateAddress',params)

// // 搜索
// // 首页搜索
// export const searchProduct=(params)=>http.post('/webindex/public/productList',params)
// // 订单搜索
// export const searchOrder=(params)=>http.post('/webMy/private/myOrderList',params)
// // 反馈搜索
// export const searchEvaluate=(params)=>http.post('/webMy/private/myEvaluateList',params)