<template>
  <div class="main">
    <div class="main-about">
      <p>Выбери адрес доставки</p>
      <div class="main-conner">
        <div class="main-box" v-html="content">
        
        </div>
        
      </div>
    </div>
 </div>
</template>

<script>
import {postSys} from '../utils/api'
export default {
  data () {
    return {
      content:''
    }
  },
  methods: {
    aboutusers(){
      let params=1
      postSys({category:params}).then(res=>{
        console.log(res);
        this.content=res.data.data.content
      }).catch(() => {})
    }
  },
  created () {
    this.aboutusers()
  },
  mounted () {

  },
  components: {

  },
  computed: {

  },
  watch: {

  },
}
</script>

<style lang='scss' scoped>
.main{
  width: 100%;
background: #F5F6F8;
padding-left: 95px;
padding-top: 40px;
.main-about{
  width: 1730px;
background: #FFFFFF;
border-radius: 36px 36px 36px 36px;
opacity: 1;
padding-left: 28px;
padding-top: 29px;
p{
  font-size: 24px;
font-weight: 500;
color: #333333;
line-height: 28px;
}
.main-conner{
  display: flex;
  img{
    width: 658px;
height: 399px;
margin-left: 35px;
  }
  .main-box{
    margin-top: 35px;
    font-size: 16px;
font-weight: 400;
color: #333333;
line-height: 25px;
  }
}
}
}
</style>
