import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:localStorage.getItem('token') || '',
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, data){
      console.log(data);
      state.token=data
      localStorage.setItem('token',data)
    }
  },
  actions: {
    userinfos({commit},data){
      commit("setUserInfo", data)
    }
  },
  modules: {
  }
})
