<template>
  <div>
    <!-- 顶部模块 -->
    <div class="top">
      <div class="top-box">
        <div class="logo">
          <img src="../assets/indeximg/image 1.png" alt="" />
        </div>
        <div class="search">
          <img src="../assets/indeximg/Frame.png" alt="" />
          <input
            type="text"
            placeholder="Введите имя или номер товара"
            v-model="input"
            @input="search"
          />
        </div>
        <div class="top-right">
          <div class="btn" v-if="$store.state.token == ''">
            <button @click="login">залогин</button>
          </div>
          <div v-else>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link" style="cursor: pointer;">
                <img src="../assets/Frame.png" alt="" />Имя пользователя
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">Мой заказ.</el-dropdown-item>
                <el-dropdown-item command="b">Мои отзывы.</el-dropdown-item>
                <el-dropdown-item command="c">Моя оценка.</el-dropdown-item>
                <el-dropdown-item command="d">Мой адрес.</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="aa"></div>
          </div>
          <div @click="goshopcar" style="cursor: pointer;">
            <img src="../assets/indeximg/Group 1.png" alt="" />Тележка для
            покупок
          </div>
        </div>
      </div>
    </div>
    <!-- 顶部导航 -->
    <div class="nav-bg">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        background-color="#E60012"
        text-color="#fff"
        active-text-color="none"
        mode="horizontal"
        router
        @select="changeMenu"
      >
        <el-menu-item index="/index">
          <span slot="title">Товар на продажу</span>
        </el-menu-item>
        <el-menu-item index="/hotshop">
          <span slot="title">Все товары.</span>
        </el-menu-item>
        <el-menu-item index="/about">
          <span slot="title">О нас.</span>
        </el-menu-item>
        <el-menu-item index="/feedback">
          <span slot="title">Обратная связь мнений</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div>
      <router-view
        v-if="$route.path !== '/index' && $route.path !== '/hotshop'"
      ></router-view>
      <index v-show="$route.path === '/index'" ref="indexRef"> </index>
      <hotshop v-show="$route.path === '/hotshop'" ref="hotshopRef"></hotshop>
      <!-- <about v-show="$route.path === '/about'" ref="aboutRef"></about>
        <feedback v-show="$route.path === '/feedback'" ref="feedbackRef"></feedback> -->
    </div>
    <footer>
      <!-- 服务 -->
      <div class="server">
        <div class="servers-boxs">
          <div class="server-box">
            <div><img src="../assets/indeximg/Frame (3).png" alt="" /></div>
            <div class="title">У производителя распродажа</div>
            <div class="description">
              <div>Источник этого товара продается</div>
              <div>непосредственно</div>
              <div>производителям</div>
            </div>
          </div>
          <div class="line"></div>
          <div class="server-box">
            <div><img src="../assets/indeximg/Frame (4).png" alt="" /></div>
            <div class="title">Финансирование.</div>
            <div class="description">
              Средства для торговли продуктом хранятся на независимом счете
              третьей стороны, которое не будет выплачено продавцу до тех пор,
              пока сделка не будет удовлетворена покупателем и подтверждена
            </div>
          </div>
          <div class="line"></div>
          <div class="server-box">
            <div><img src="../assets/indeximg/Group 24.png" alt="" /></div>
            <div class="title">Семь дней на доставку</div>
            <div class="description">
              После получения заказа покупателя продавец должен был доставить
              товар через 7 дней, как и договаривались
            </div>
          </div>
          <div class="line"></div>
          <div class="server-box">
            <div><img src="../assets/indeximg/Frame (5).png" alt="" /></div>
            <div class="title">Оплата по доставке</div>
            <div class="description">
              После того, как покупатель получил товар, он подтвердил платеж в
              соответствии с требованиями покупателя о покупке
            </div>
          </div>
          <div class="line"></div>
          <div class="server-box">
            <div><img src="../assets/indeximg/Frame (6).png" alt="" /></div>
            <div class="title">Агент продукции</div>
            <div class="description">
              Выберите самых элитных бизнесменов из всех отраслей
              промышленности, прокси-оперативная линия соответствует моделям,
              которые помогут вам создать качественный интегрированный сервис
            </div>
          </div>
        </div>
      </div>
      <!-- 版权备案 -->
      <div class="copyright-bg">
        <div class="copyright-box">
          <div class="copyright-top">
            <div>
              <img
                src="../assets/indeximg/Frame (7).png"
                alt=""
              />адрес：Ростов-на-дону, Лу. Леошенко, 1б, рынок "эркюль",
              павильон 85
            </div>
            <div>
              <img src="../assets/indeximg/Frame (8).png" alt="" />Почтов
              ящик：boyaby@mail.ru
            </div>
            <div>
              <img
                src="../assets/indeximg/Frame (9).png"
                alt=""
              />телефон：+79185905888OPT；+79882555678OPT
            </div>
          </div>
          <div class="copyright-botm">
            Товар, украшающий бойаби на рыбалке ростова-на-дону, пришел
            непосредственно от производителя.
          </div>
        </div>
      </div>
    </footer>

    <!-- 登录 -->
    <el-dialog
      title="Добро пожаловать boyaby"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="dialog-main">
        <div class="aa">
          <span class="s1">аккаунт:</span>
          <p class="search">
            <input
              type="text"
              v-model="params.account"
              placeholder="Введите номер счета."
            />
          </p>
        </div>
        <div>
          <span class="s2">код:</span>
          <p class="search">
            <input
              type="password"
              v-model="params.password"
              placeholder="Введите пароль."
            />
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button @click="determine">Залогинись немедленно.</button>
        <p>Никаких номеров счетов, пожалуйста, свяжитесь с нами</p>
        <p class="p1">Телефон: +79882555678OPT</p>
      </span>
    </el-dialog>
    <!-- 在线留言 -->
    <div
      class="more"
      v-if="$store.state.token == '' && $route.path != '/goodslist'"
    >
      <img src="../assets/indeximg/Group 34.png" alt="" @click="leamessage" />
    </div>
    <el-dialog
      title="Добро пожаловать boyaby"
      :visible.sync="dialogVisible1"
      :before-close="handleClose"
      class="dialog"
    >
      <div class="dialogs">
        <div>
          <span class="s1">им:</span>
          <p class="search">
            <input
              type="text"
              v-model="leave.name"
              placeholder="Введите имя."
            />
          </p>
        </div>
        <div>
          <span class="s2">Контактная информация.:</span>
          <p class="search">
            <input
              type="text"
              v-model="leave.phone"
              placeholder="Введите контактную информацию"
            />
          </p>
        </div>
        <div>
          <span class="s2">Сообщение.:</span>
          <p class="search">
            <input
              type="text"
              v-model="leave.content"
              placeholder="Пожалуйста, введите сообщение."
            />
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button @click="message">Оставьте сообщение.</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { logions, leaveMessage } from "../utils/api";
import Index from "./Index.vue";
import hotshop from "./hotshop.vue";
import about from "./about.vue";
import feedback from "./feedback.vue";
export default {
  data() {
    return {
      setTime: "",
      dialogVisible: false,
      dialogVisible1: false,
      dayIndex: 0,
      params: {
        account: "",
        password: "",
        category: "1",
      },
      leave: {
        name: "",
        phone: "",
        content: "",
      },
      input: "",
    };
  },
  components: {
    Index,
    hotshop,
    about,
    feedback,
  },
  created() {},
  methods: {
    // 切换盗汗栏
    changeMenu(val) {
      console.log(val);
      if (val == "/index") {
          this.$refs.indexRef.Product(this.input);
        } else if (val == "/hotshop") {
          this.$refs.hotshopRef.Product(this.input);
        }
    },
    // 搜索
    search(val) {
      clearTimeout(this.setTime);
      this.setTime = setTimeout(() => {
        if (this.$route.path == "/index") {
          this.$refs.indexRef.Product(this.input);
        } else if (this.$route.path == "/hotshop") {
          this.$refs.hotshopRef.Product(this.input);
        }
      }, 500);

      console.log(211);
    },
    // 在线留言
    leamessage() {
      console.log(111);
      this.dialogVisible1 = true;
    },
    message() {
      leaveMessage(this.leave).then((res) => {
        console.log(res);
        this.leave = {
          name: "",
          phone: "",
          content: "",
        };
        this.dialogVisible1 = false;
        this.$message({
          message: "Поздравляю, сообщение получилось.",
          type: "success",
        });
      });
    },
    // 登录弹出框
    login() {
      this.dialogVisible = true;
    },
    // 确定
    determine() {
      console.log(this.params);
      logions(this.params).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          // localStorage.setItem('token',res.data.data.token)
          this.$store.dispatch("userinfos", res.data.data.token);
          this.params.account = "";
          this.params.password = "";
          this.dialogVisible = false;
          this.$message({
            message: "Поздравляю с успешным входом！",
            type: "success",
          });
        } else if (res.data.msg == "Account does not exist") {
          this.$message.error("Неправильный аккаунт или пароль.");
        }
      }).catch(() => {});
    },
    handleCommand(command) {
      let token = localStorage.getItem("token");
      if (token == null) {
        this.$message({
          message: "Регистрация просрочена, пожалуйста, войдите повторно.",
          type: "warning",
        });
      } else {
        if (command == "a") {
          this.$router.push({ path: "/ordergoods" });
        } else if (command == "b") {
          this.$router.push({ path: "/users" });
        } else if (command == "c") {
          this.$router.push({ path: "/evaluate" });
        } else {
          this.$router.push({ path: "/address" });
        }
      }
    },
    handleClose(done) {
      done();
    },
    goshopcar() {
      let token = localStorage.getItem("token");
      if (token == null) {
        this.$message({
          message: "Регистрация просрочена, пожалуйста, войдите повторно.",
          type: "warning",
        });
      } else {
        this.$router.push({ path: "/shopcar" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  position: fixed;
  top: 40%;
  right: 60px;
  z-index: 99;
  img {
    width: 75px;
    height: 75px;
  }
}
.el-dropdown {
  img {
    margin-right: 12px;
    width: 54px;
    height: 54px;
  }
  margin-right: 41px !important;
  font-size: 16px;
  font-weight: 500;
  color: #134fc0;
}
.el-header {
  padding: 0px;
}
.el-main {
  padding: 0px;
}
.el-main {
  margin-top: 110px;
  padding: 0px;
}
.top {
  width: 100%;
  .top-box {
    width: 1730px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      padding: 21px 0 15px 0;
    }
    .search {
      width: 668px;
      height: 54px;
      // background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 1px solid #b6bbc8;
      display: flex;
      align-items: center;
      img {
        display: block;
        padding: 0 21px;
      }
      input {
        display: block;
        width: 274px;
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        border: 0;
        outline: none;
        line-height: 21px;
      }
      input::-webkit-input-placeholder {
        color: #b6bbc8;
        opacity: 1;
      }
    }
    .top-right {
      display: flex;
      align-items: center;
      .btn {
        margin-right: 42px;
        button {
          width: 156px;
          height: 54px;
          background: #134fc0;
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          text-align: center;
          line-height: 54px;
          font-size: 18px;
          color: #ffffff;
          border: 0;
        }
      }
      img {
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
}
.nav-bg {
  width: 100%;
  height: 64px;
  background-color: #e60012;
  .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    & > div {
      height: 64px;
      line-height: 64px;
      color: #ffffff;
      font-size: 18px;
      padding: 0 20px;
    }
  }
}
footer {
  width: 100%;
  background: #f5f6f8;
  padding-top: 92px;
  // 服务
  .server {
    width: 1730px;
    margin: 0 auto;
    .servers-boxs {
      display: flex;
      justify-content: space-between;
      .server-box {
        width: 269px;
        .title {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        .description {
          font-size: 14px;
          color: #999999;
          margin: 20px 0 12px 0;
          & > div {
            line-height: 18px;
          }
        }
      }
      .line {
        width: 1px;
        height: 91px;
        // background-color: rgba(182, 187, 200, 0.19);
        background-color: #fff;
        margin-top: 45px;
      }
    }
  }
  .copyright-bg {
    width: 100%;
    height: 127px;
    background-color: #fff;
    background: #333333;
    .copyright-box {
      width: 100%;
      margin: 0 auto;
      .copyright-top {
        font-size: 16px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.29);
        padding: 21px 95px;
        img {
          vertical-align: middle;
          margin-right: 11px;
        }
      }
      .copyright-botm {
        font-size: 14px;
        color: #999999;
        text-align: center;
        padding: 20px 0 23px 0;
      }
    }
  }
}
::v-deep .el-dialog {
  width: 837px;
  height: 423px;
  padding: 30px;
  border-radius: 36px;
  .title {
    font-size: 24px;
    color: #333333;
  }
  .dialog-main {
    .aa {
      margin-top: 10px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: none;
      .s1 {
        width: 105px;
        display: inline-block;
        text-align: right;
        color: #333333;
        font-size: 15px;
        padding-right: 25px;
        padding-top: 20px;
      }
      .s2 {
        width: 105px;
        display: inline-block;
        text-align: right;
        color: #333333;
        font-size: 16px;
        padding-right: 25px;
        padding-top: 20px;
      }
    }
    .search {
      width: 682px;
      height: 54px;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 1px solid #b6bbc8;
      display: flex;
      align-items: center;
      margin-top: 20px;
      input {
        padding-left: 29px;
        display: block;
        width: 274px;
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        border: 0;
        outline: none;
        line-height: 21px;
      }
      input::-webkit-input-placeholder {
        color: #b6bbc8;
        opacity: 1;
      }
    }
  }
  .dialog-footer {
    text-align: center;
    button {
      width: 576px;
      height: 64px;
      background: #134fc0;
      border-radius: 12px 12px 12px 12px;
      color: #fff;
      border: none;
      font-size: 20px;
      margin-top: 38px;
      margin-right: 100px;
    }
    p {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-top: 20px;
    }
    .p1 {
      margin-top: 12px !important;
    }
  }
}
.dialogs {
  .aa {
    margin-top: 10px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: none;
    .s1 {
      width: 250px;
      display: inline-block;
      text-align: right;
      color: #333333;
      font-size: 13px;
      font-weight: 400;
      padding-right: 25px;
      padding-top: 20px;
    }
    .s2 {
      width: 250px;
      display: inline-block;
      text-align: right;
      color: #333333;
      font-size: 13px;
      font-weight: 400;
      padding-right: 25px;
      padding-top: 20px;
    }
  }
  .search {
    width: 560px;
    height: 54px;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #b6bbc8;
    display: flex;
    align-items: center;
    margin-top: 20px;
    input {
      padding-left: 29px;
      display: block;
      width: 274px;
      height: 25px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      border: 0;
      outline: none;
      line-height: 21px;
    }
    input::-webkit-input-placeholder {
      color: #b6bbc8;
      opacity: 1;
    }
  }
}
.dialog-footer {
  text-align: center;
  button {
    width: 576px;
    height: 64px;
    background: #134fc0;
    border-radius: 12px 12px 12px 12px;
    color: #fff;
    border: none;
    font-size: 20px;
    margin-top: 38px;
    margin-right: 100px;
  }
  p {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    margin-top: 20px;
  }
  .p1 {
    margin-top: 12px !important;
  }
}
::v-deep .el-input {
  width: 682px;
  height: 54px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 1px solid #b6bbc8;
}
::v-deep .el-dialog__body {
  padding: 0px !important;
}
::v-deep .el-dialog__header {
  padding: 0px !important;
}
::v-deep .el-dialog__footer {
  padding: 0px !important;
}
.active {
  background-color: #c00e0f;
}
::v-deep .el-menu {
  display: flex;
  justify-content: space-around;
  height: 64px;
}
::v-deep .el-menu-item {
  flex: 1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: none !important;
}
.el-menu-item.is-active {
  background-color: #c00e0f !important;
  border-bottom: none !important;
}
</style>
