import axios from 'axios'
import router from '../router/index'
import {
  Message,
  MessageBox
} from 'element-ui';
const http = axios.create({
  // baseURL: 'http://192.168.124.19:6070',
  baseURL: 'https://www.boyashop.cn/fishing',
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  // if(localStorage.getItem('token')){
    config.headers.Authorization = localStorage.getItem('token') 
    return config
  // }
  // 在发送请求之前做些什么
  // return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  console.log(1111,response);
  // 对响应数据做点什么
  // if (response.data.code === 200) {
  //   Message({
  //     message: response.data.msg,
  //     type: 'success'
  //   });
  // }
// 无效token返回登录
  if (response.data.code === 401) {
    MessageBox('Просрочен ли вход повторно ?', 'Подсказка', {
      confirmButtonText: 'Определение',
      cancelButtonText: 'Отменить',
      type: 'warning'
    }).then(() => {
      localStorage.removeItem('token')
      router.push('/')
      location.reload()
    }).catch(() => {
     Message({
        type: 'info',
        message: 'Отменено'
      });
    });
    return Promise.reject(response)

  } else if (response.data.code === 200) {
    return Promise.resolve(response);
  } else {
    console.log(111);
       Message.error({
      message: response.data.msg,
    });
    return Promise.reject(response)
  }

  

  
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});


export default http