<template>
  <div class="main" @click="showModel=false">
    <div class="container">
      <p class="container-top">Обратная связь мнений</p>
      <div class="center">
        <div class="container-left">
          <p class="container-title">Свяжись с нами.</p>
          <div>
            <p>Адрес: официальный сайт BoyaBy-российская федерация Ростов-на-дону, Лу. Леошенко, 1б, рынок "эркюль",павильон 85</p>
            <p>OPT: +7-918-59-05-888</p>
            <p>Телефон :+79185905888</p>
          </div>
          <p class="container-btm">План поездки.</p>
          <img src="../assets/微信图片_20230831161125.png" alt=""/>
        </div>
        <div class="container-right">
          <p class="right-top">Мнение по обратной связи</p>
          <p class="inpt inpt-top">
            <span>им：</span>
            <el-input v-model="params.name" placeholder="Введите ваше имя, пожалуйста"></el-input>
          </p>
          <p class="inpt">
            <span>телефона.</span>
            <el-input v-model="params.phone" placeholder="Пожалуйста, введите ваш телефон"></el-input>
          </p>
          <p>
            <span>Твой вопрос.</span>
          
              <el-input type="textarea" :rows="8" placeholder="Пожалуйста, введите вопрос" v-model="params.content"
                class="ipttext">
              </el-input>
          </p>
          <button @click="back">представ</button>
        </div>
      </div>
    </div>
    <div class="dialog" v-show="showModel">
      <p class="dialog-title">
        <span class="s1">подсказк</span
        ><span class="s2" @click="showModel = false">×</span>
      </p>
      <div class="containers">
        <img src="../assets/Frame_slices/Frame.png" alt="" />
        <p>Обратная связь работает..</p>
      </div>
      <button @click.stop="gg">Проверь мою обратную связь</button>
    </div>
  </div>
</template>

<script>
import { feedback } from '../utils/api'
export default {
  data() {
    return {
      showModel: false,
      params: {
        name: '',
        phone: '',
        content: ''
      }
    }
  },
  methods: {
    gg() {
      console.log(111);
      this.$router.push('/users')
    },
    // 意见反馈
    back() {
      feedback(this.params).then(res => {
        if (res.data.code == 200) {
          this.showModel = true
          this.params = {}
        }
      })
    }
  },
  created() {

  },
  mounted() {

  },
  components: {

  },
  computed: {

  },
  watch: {

  },
}
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  padding-left: 95px;
  padding-top: 40px;
  background: #f5f6f8;

  .container {
    width: 1730px;
    background: #ffffff;
    border-radius: 36px 36px 36px 36px;
    padding: 30px;

    .container-top {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 34px;
      margin-bottom: 34px;
    }

    .center {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .container-title {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 15px;
        line-height: 28px;
      }

      div {
        p {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 20px;
          line-height: 22px;
        }
      }

      .container-btm {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }

    .container-left {
      width: 1014px;
      margin-right: 83px;
      img{
        width: 1014px;
        height: 484px;
      }
    }

    .container-right {
      width: 576px;
      .right-top {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin: 0px !important;
      }
      .inpt-top{
        margin-top: 15px !important;
      }
      .inpt {
        margin-top: 25px;

        span {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
      .ipttext{
        margin-top: 10px;
        margin-bottom: 24px;
      }
      button {
        width: 576px;
        height: 64px;
        background: #134fc0;
        border-radius: 12px 12px 12px 12px;
        color: #ffffff;
        border: none;
      }
    }
  }
}
.dialog {
  position: fixed;
  top: 20%;
  left: 35%;
  width: 580px;
  height: 583px;
  background: #ffffff;
  border-radius: 36px 36px 36px 36px;
  opacity: 1;
  padding: 30px;
  .dialog-title {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    .s2 {
      display: inline-block;
      width: 11px;
      height: 11px;
    }
  }
  .containers {
    text-align: center;
    img {
      width: 150px;
      margin-top: 84px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      margin-top: 43px;
      margin-bottom: 77px;
    }
  }
  button {
    width: 316px;
    height: 64px;
    background: #134fc0;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    margin-left: 105px;
    border: none;
    color: #fff;
  }
}
::v-deep .el-input {
  width: 576px !important;
  background: none;
  border: none !important;
}

::v-deep .el-input__inner {
  width: 576px;
  height: 59px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 1px solid #b6bbc8;
}

::v-deep .el-textarea__inner {
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 1px solid #b6bbc8;
}
::v-deep [data-v-9ea40744] .el-input {
  width: none !important;
}
</style>
