<template>
  <div class="main">
    <div class="classifi">
      <p>Все по категориям.</p>
      <div>
        <div class="classifi-box">
        <span
          v-for="(item, index) in classifiList"
          :key="index"
          :class="{ active: dayIndex == index }"
          @click="shopclassifi(item,index)"
          >{{ item.content }}</span
        >
      </div>
      <div class="classifi-box box1" v-show="list.length>0">
        <span
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: Index == index }"
          @click="classifi(item,index)"
          >{{ item.content }}</span
        >
      </div>
      </div>
    </div>
    <p class="shop-title">Все товары.</p>
    <div class="content">
      <div class="shop-box" v-for="(item, index) in Productlist" :key="index">
      <img
        :src="item.thumbnail"
        alt=""
      />
      <p class="p1">{{item.typeName}}{{item.typeNameTwo}} </p>
      <p class="p2" v-show="$store.state.token!=''">Номер товара：{{item.number}} </p>
      <p class="p3">
        {{item.name}}
      </p>
      <p class="p4" v-show="$store.state.token!=''">￥<span> {{item.price}} </span></p>
      <p class="p5"><span>Производитель:</span> {{item.manufacturer}} </p>
      <button @click="shopdetils(item)">покупк</button>
    </div>
    </div>
    <div class="page">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="params.pageNumber"
      :page-size="params.pageSize"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
  </div>
</template>

<script>
import {allType,postProduct} from '../utils/api'
export default {
  data() {
    return {
      classifiList: [],
      dayIndex: 0,
      Index:0,
      params:{
        pageSize:12,
        pageNumber:1,
        searchValue: '',
        category:'',
        typeId:''
      },
      Productlist:[],
      total:0,
      list:[]
    };
  },
  methods: {
     // 购买
  shopdetils(e){
    console.log(e.productId);
    this.$router.push({
      path: '/goodslist',
      query: {
        productId:JSON.stringify(e.productId)
      },
    })
  },
    // 商品列表
    Product(val){
      this.params.searchValue = val || ''
      postProduct(this.params).then(res=>{
        console.log(res,'2525');
        this.Productlist=res.data.data.records
        this.total=res.data.data.total
      }).catch(() => {})
    },
    handleSizeChange(val){
      this.params.pageSize=val
      this.Product()
    },
    handleCurrentChange(val){
      this.params.pageNumber=val
      this.Product()
    },
    // 所有分类
    classify(){
      allType().then(res=>{
        console.log(res);
        this.classifiList=res.data.data
      }).catch(() => {})
    },
    shopclassifi(item,index) {
      this.dayIndex = index;
      this.list=this.classifiList[index].children
      console.log(item);
      this.params.category=1
      this.params.typeId=item.id
      this.Product()
    },
    classifi(item,index){
      this.Index = index;
      console.log(item);
      this.params.category=2
      this.params.typeId=item.id
      this.Product()
    },
    goods() {
      this.$router.push("/goodslist");
    },
  },
  created() {
    this.Product()
    this.classify()
  },
  mounted() {},
  components: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  flex-wrap: wrap;
}
.main {
  width: 100%;
  box-sizing: border-box;
  padding-left: 95px;
  margin: 0px;
  background-color: #F5F6F8;;
  padding-top: 50px;
  background-image:url(../assets/indeximg/back.png) ;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;
  .classifi {
    width: 1730px;
    background: #fff;
    border-radius: 36px 36px 36px 36px;
    padding: 20px;
    p {
      color: #333;
      font-size: 24px;
    }
    .box1{
      border-top: 1px solid #b6bbc8;
    }
    .classifi-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      span {
        cursor: pointer;
        display: inline-block;
        margin-top: 20px;
        padding: 9px 31px 9px 31px;
        background: #ffffff;
        border-radius: 40px 40px 40px 40px;
        border: 1px solid #b6bbc8;
        margin-right: 20px;
      }
    }
  }
  .shop-title {
    margin-top: 40px;
    width: 191px;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .shop-box {
    width: 395px;
    background: #ffffff;
    border-radius: 36px 36px 36px 36px;
    opacity: 1;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  .shop-box {
    width: 395px;
    background: #ffffff;
    border-radius: 36px 36px 36px 36px;
    padding-top: 56px;
    padding-bottom: 24px;
    img {
      width: 321px;
      height: 321px;
      border-radius: 36px 36px 36px 36px;
      margin-left: 35px;
    }
    .p1 {
      width: 358px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-top: 30px;
      margin-left: 15px;
      margin-bottom: 8px;
    }
    .p2 {
      width: 358px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-left: 15px;
      margin-bottom: 8px;
    }
    .p3 {
      // width: 358px;
      font-size: 1.25rem;
      font-weight: 500;
      color: #333333;
      // margin-left: 15px;
      padding: 0 22px 0 15px;
      margin-bottom: 3px;
      // padding: ;
      text-align: justify;
      display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .p4 {
      display: flex;
      width: 44px;
      height: 34px;
      font-size: 24px;
      font-weight: 500;
      color: #e23839;
      margin-left: 15px;
      margin-bottom: 12px;
      margin-top: 10px;
    }
    .p5 {
      width: 358px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;
      margin-bottom: 24px;
      color: #333;
      span {
        color: #999999 !important;
      }
    }
    button {
      width: 302px;
      height: 48px;
      background: #134fc0;
      border: none;
      border-radius: 12px 12px 12px 12px;
      padding: auto;
      margin-left: 43px;
      color: #fff;
    }
  }
}
.pagination {
  margin-left: 627px;
}
.active {
  color: #fff;
  background-color: #134fc0 !important;
}
.page{
  width: 100%;
}
::v-deep .el-pagination{
  margin: auto;
  text-align: center;
  .el-pager li{
    border: 1px solid #ccc;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    text-align: center;
    padding-top: 5px;
  }
  .el-input{
    height: 0px !important;
    border: 0px !important;
  }
  .btn-next{
    width: 74px;
    height: 40px;
    border: 1px solid #ccc;
    margin-left: 10px;
  }
  .btn-prev{
    width: 74px;
    height: 40px;
    border: 1px solid #ccc;
  }
  .el-pagination__jump{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
  .el-pagination__editor.el-input .el-input__inner{
    height: 40px;
  }
}
</style>
