import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/index',  // 重定向:重新指向其它path,会改变网址
    children:[
      {
        path:'/index',
        name:'index',
        component:()=>import('../views/Index.vue')
      },
      {
        path:'/hotshop',
        name:'hotshop',
        component:()=>import('../views/hotshop.vue')
      },
      {
        path:'/goodslist',
        name:'goodslist',
        component:()=>import('../views/goodslist.vue')
      },
      {
        path:'/placeorder',
        name:'placeorder',
        component:()=>import('../views/placeorder.vue')
      },
      {
        path:'/about',
        name:'about',
        component:()=>import('../views/about.vue')
      },
      {
        path:'/feedback',
        name:'feedback',
        component:()=>import('../views/feedback.vue')
      },
      {
        path:'/shopcar',
        name:'shopcar',
        component:()=>import('../views/shopcar.vue')
        // 购物车
      },
      {
        path:'/ordergoods',
        name:'ordergoods',
        component:()=>import('../views/ordergoods.vue')
        // 我的订单
      },
      {
        path:'/users',
        name:'users',
        component:()=>import('../views/users.vue')
        // 我的反馈
      },
      {
        path:'/evaluate',
        name:'evaluate',
        component:()=>import('../views/evaluate.vue')
        // 我的评价
      },
      {
        path:'/address',
        name:'address',
        component:()=>import('../views/address.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
