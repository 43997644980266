<template>
  <div>
    <!-- 轮播 -->
    <el-carousel :interval="5000" arrow="always" height="879px">
      <el-carousel-item v-for="(item, index) in pointImgList" :key="index">
        <img :src="item.bannerUrl" class="image" />
      </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="section-box">
      <div class="section-top">Горячие товары выстроены</div>
      <div class="section">
        <div class="sections" v-for="(item, index) in goodsList" :key="index">
          <div class="num-prct" v-if="item.sort == 1">
            <img src="../assets/indeximg/Group 322.png" alt="" />
          </div>
          <div class="num-prct" v-else-if="item.sort == 2">
            <img src="../assets/indeximg/Group 323.png" alt="" />
          </div>
          <div class="num-prct" v-else-if="item.sort == 3">
            <img src="../assets/indeximg/Group 324.png" alt="" />
          </div>
          <div class="num-prct" v-else>
            <img src="../assets/indeximg/Group 325.png" alt="" />
          </div>
          <div class="number">{{ item.sort }}</div>
          <div class="shop-prct"><img :src="item.thumbnail" alt="" /></div>
          <div class="title">{{ item.typeName }}{{ item.typeNameTwo }}</div>
          <div class="subhead" v-show="$store.state.token != ''">
            Номер товара：{{ item.number }}
          </div>
          <div class="description">
            {{ item.name }}
          </div>
          <div class="price" v-show="$store.state.token != ''">
            <span>¥</span> {{ item.price }}
          </div>
          <div class="price-j">
            <span>Производитель:</span> {{ item.manufacturer }}
          </div>
          <div class="btns">
            <button @click="shopdetils(item)">покупк</button>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!-- 更多 -->
    <div class="more" @click="more"><button>Осмотреть товар</button></div>
  </div>
  <!-- </div> -->
</template>

<script>
import { postBanner, postProduct } from "../utils/api";
export default {
  data() {
    return {
      pointImgList: [],
      params: {
        pageNumber: 1,
        pageSize: 8,
        ifSellWell: "1",
        ifSort: "",
        searchValue: "",
      },
      goodsList: [],
      input: "",
    };
  },
  watch: {
    "$route.query": {
      handler: function (val, oldVal) {
        console.log(val, oldVal, 5959595);
      },
      deep: true,
    },
  },
  methods: {
    // 获取轮播图
    banner() {
      postBanner()
        .then((res) => {
          console.log(res, "11");
          this.pointImgList = res.data.data;
        })
        .catch(() => {});
    },
    //  商品列表
    Product(val) {
      this.params.searchValue = val || "";

      console.log(this.params, "123");
      postProduct(this.params)
        .then((res) => {
          console.log(res);
          this.goodsList = res.data.data.records;
          console.log(this.goodsList, "23456");
        })
        .catch(() => {});
    },
    // 购买
    shopdetils(e) {
      console.log(e.productId);
      this.$router.push({
        path: "/goodslist",
        query: {
          id: JSON.stringify(e.productId),
        },
      });
    },
    // 更多
    more() {
      this.$router.push("/hotshop");
    },
  },
  created() {
    this.banner();
    this.Product();
    this.input = this.$route.query.input;
  },
  mounted() {},
  components: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  height: 100%;
}
// 内容
.section-box {
  width: 100%;
  margin: 0 auto;
  background-color: #f5f6f8;
  padding-left: 95px;
  .section-top {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    padding: 60px 0;
  }
  .section {
    display: flex;
    flex-wrap: wrap;
    .sections {
      width: 395px;
      margin-right: 50px;
      // height: 712px;
      background: #ffffff;
      border-radius: 36px 36px 36px 36px;
      margin-bottom: 50px;
      opacity: 1;
      position: relative;
      .num-prct {
        position: absolute;
        width: 134px;
        height: 134px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .number {
        font-size: 54px;
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 20px;
      }
      .shop-prct {
        padding-top: 50px;
        text-align: center;
        img {
          width: 321px;
          height: 321px;
        }
      }
      .title {
        font-size: 14px;
        color: #999999;
        padding-left: 15px;
        line-height: 20px;
      }
      .subhead {
        font-size: 14px;
        color: #333333;
        padding-left: 15px;
        line-height: 20px;
        margin-top: 8px;
      }
      .description {
        font-size: 1.25rem;
        text-align: justify;
        font-weight: 500;
        color: #333333;
        padding: 0 1.375rem 0 0.9375rem;
        // padding-left: 15px;
        line-height: 28px;
        margin-top: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price {
        color: #e60012;
        font-size: 24px;
        font-weight: 500;
        padding-left: 15px;
        line-height: 34px;
        margin-bottom: 12px;
        span {
          font-size: 14px;
        }
      }
      .price-j {
        font-size: 14px;
        color: #333333;
        padding-left: 15px;
        margin-top: 12px;
        line-height: 20px;
        margin-bottom: 24px;
        span {
          color: #999999;
        }
      }
      .btns {
        text-align: center;
        padding: 24px 0;
        button {
          width: 302px;
          height: 48px;
          background: #134fc0;
          border-radius: 12px 12px 12px 12px;
          opacity: 1;
          border: 0;
          font-size: 18px;
          color: #ffffff;
        }
      }
    }
  }
}
.more {
  text-align: center;
  background-color: #f5f6f8;
  button {
    width: 459px;
    height: 64px;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #134fc0;
    background-color: #f5f6f8;
    font-weight: 500;
    color: #134fc0;
    text-align: center;
  }
}
</style>
